const adminApi = process.env.VUE_APP_ADMIN_API
const uploadApi = process.env.VUE_APP_UPLOAD_API

// fastMockUrl: 'https://www.fastmock.site/mock/7fbc35e4e435a167eb56974a6ce2ee04/isameng/api/'

// interface Api {
//   loginUrl: string
//   upload: string
// }

const API = {
  loginUrl: `${adminApi}/login2.html`,
  uploadUrl: `${uploadApi}/api/fileUpload/appletsUpload.html`,
  delCategoryNode: '/category/delCategoryNode.html',
  findCategoryListByTree: '/category/findCategoryListByTree.html',
  findCategoryListByTreeApi: '/api/category/findCategoryListByTree.html',
  findContentByCateId: '/category/findContentByCateId.html',
  findContentByCateIdApi: '/api/category/findContentByCateId.html',
  modifyCategoryName: '/category/modifyCategoryName.html',
  addCategoryNode: '/category/addCategoryNode.html',
  modifyContentByContentId: '/category/modifyContentByContentId.html',
  addContent: '/category/addContent.html',
  editHelpLog: '/helpLog/editHelpLog.html',
  insertHelpLog: '/helpLog/insertHelpLog.html',
  deleteHelpLog: '/helpLog/deleteHelpLog.html',
  findHelpLog: '/helpLog/findHelpLog.html',
  pollingOrderDetail: '/wechatapi/applets/wxOrder/pollingOrderDetail.html', // 确认订单查看回执
  checkPollingOrder: '/wechatapi/applets/wxOrder/pollingOrderById.html', // 订单列表查看回执
  companyList: '/api/invoice/companyList.html', // 获取公司列表
  appletsUpload: `/api/fileUpload/appletsUpload.html`, // 图片上传
  queryElectronicInvoiceInfo: `/api/invoice/queryElectronicInvoiceInfo.html`, //获取抬头信息
  regionList: `/api/invoice/regionList.html`, //获取省市区信息
  addInvoice: `/api/invoice/addInvoice.html`, //添加发票信息
  checkInvoice: `/api/invoice/checkInvoice.html`, //校验发票信息
  obtainInvoiceApplication: `/api/invoice/obtainInvoiceApplication.html` //手机号获取开票申请记录
}

export default API
