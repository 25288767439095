import axios from 'axios'
import qs from 'qs'
import API from '@/http/api'
import { Toast } from 'vant'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

instance.interceptors.request.use(
  config => {
    // showLoading('main')
    if (config.url === API.uploadUrl) {
      config.headers['Content-Type'] = 'multipart/form-data'
    } else {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (config.method === 'post' && config.url !== API.uploadUrl) {
      config.data = qs.stringify(config.data)
      // console.log(config.data)
    }
    return config
  },
  error => {
    console.log('请求拦截error', error)
    return Promise.reject(error)
  }
)

// interface TipsMessage {
//   [propName: number]: string
// }

// const tipsMessage: TipsMessage = {
//   100: '登录失败  用户名密码不对或账号被锁定',
//   101: 'TOKEN失效  Token已失效，请重新调登录接口',
//   102: '参数错误  请根据接口参数要求进行传参',
//   103: '未登录',
//   300: '接口无访问权限  没有权限访问该接口',
//   301: '数据无访问权限  没有权限访问该设备数据',
//   302: '服务到期  设备服务到期 '
// }

instance.interceptors.response.use(
  response => {
    // setTimeout(() => {
    //   hideLoading()
    // }, 200)
    // console.log(response)

    const { code, message } = response.data
    if (code !== 0) {
      // ElMessage.error(message)
      Toast(message)
      return Promise.reject(message)
    } else {
      return response.data
    }
  },
  error => {
    // setTimeout(() => {
    //   hideLoading()
    // }, 200)
    console.log('http response - err', error)
    // ElMessage({
    //   message: '服务器异常，请稍后再试',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

/**
 * 上传方法，支持对象数据源
 * @param url
 * @param data
 */
export function upload(url: string, data: Record<string, any>) {
  const keys = Object.keys(data)
  if (keys.length === 0) {
    Toast({
      message: '上传的数据不能为空'
    })
    return
  }
  const formData = new FormData()
  keys.forEach(key => {
    formData.append(key, data[key])
  })
  return new Promise((resolve, reject) => {
    axios
      .post(url, formData, {
        baseURL: process.env.VUE_APP_OPEN_API,
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
      })
      .then(response => {
        const { code, message } = response.data
        if (code !== 0) {
          // ElMessage.error(message)
          Toast(message)
          return reject(message)
        } else {
          resolve(response.data)
        }
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export default instance
